import { Anchor, Box, Button, Spinner, Text } from 'grommet';
import { FormClose } from 'grommet-icons';
import useBus, { dispatch } from 'use-bus';

import { ProcessedCard } from '../components/assistant-card/assistant-card';
import { usePlatformMutation, usePlatformQueries, usePlatformQuery, usePlatformQueryClient } from '../hooks';

const NotificationsList = ({ metadata: allMetadata }) => {
  allMetadata = allMetadata.filter((item) => item.UserActions.subscribed);

  const results = usePlatformQueries(allMetadata.map((item) => `/gateway/card/${item.Id}`));
  const readMutation = usePlatformMutation('/briefing/updatecardsstatus', {
    method: 'POST',
  });

  const { invalidateQueries } = usePlatformQueryClient();

  useBus('notifications.readall', () => {
    const updateStatusParameters = [];

    for (let i = 0; i < allMetadata.length; i++) {
      const metadata = allMetadata[i];
      const result = results[i];

      if (!result.isFetched || !result.data?.Data?.actionable) {
        continue;
      }

      const resultData = result.data.Data;

      updateStatusParameters.push({
        Id: metadata.Id,
        ReadVersion: resultData._hash,
        ReadDate: new Date().toISOString(),
      });
    }

    readMutation.mutate(updateStatusParameters, {
      onSuccess: () => {
        invalidateQueries('/userworkplace/usercards');
        for (let i = 0; i < updateStatusParameters.length; i++) {
          invalidateQueries(`/gateway/card/${updateStatusParameters[i].Id}`);
        }
      },
    });
  });

  return results
    ? results
        .filter((result) => result.isFetched)
        .map((result, index) =>
          result.data.Data.actionable && result.data.Data._hash != allMetadata[index].ReadVersion ? (
            <Box key={allMetadata[index].Id} flex={{ shrink: 0 }} style={{ cursor: 'pointer' }}>
              <ProcessedCard metadata={allMetadata[index]} data={result.data} notification shadow={false} />
            </Box>
          ) : null,
        )
    : null;
};

const Notifications = () => {
  const { data } = usePlatformQuery(`/userworkplace/usercards`);

  const toggleSidebar = () => dispatch({ type: 'sidebar.toggle', title: 'Notifications' });
  const markAllAsRead = () => dispatch({ type: 'notifications.readall' });

  return (
    <Box direction="column" fill="horizontal">
      <Box direction="row" align="center" justify="between" flex={{ shrink: 0 }} pad="medium" background="background-front">
        <Box direction="column">
          <Text weight="bold">Notifications</Text>
          <Anchor type="reset" label="Mark all as read" onClick={markAllAsRead} />
        </Box>
        <Button icon={<FormClose />} onClick={toggleSidebar} />
      </Box>
      <Box gap="small" overflow="auto">
        {data ? <NotificationsList metadata={data.Data} /> : <Spinner alignSelf="center" />}
      </Box>
    </Box>
  );
};

export default Notifications;
